@import url(https://fonts.googleapis.com/css?family=Kanit);
body,html {
  font-family: 'Kanit', sans-serif !important;
}


body, html {
  background: #f7f7f7;
}

/*
 *  STYLE 7
 */

::-webkit-scrollbar-track
{
  box-shadow : inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  background: #F7971E;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(#FFD200), to(#F7971E));
  background: linear-gradient(to bottom, #FFD200, #F7971E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.bg-ts-1 {
  background: #dad5d2;
}
.bg-ts-2 {
  background: #a19faa;
}
.bg-ts-3 {
  background: #324b69;
}
.bg-ts-4 {
  background: #bca998;
}
.bg-ts-5 {
  background: #271513;
}

.bg-background-grey {
  background: #f7f7f7;
}

.shadow-1 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.underline-head {
  text-decoration: none;
  border-bottom: 2px solid #fdb819;
}

.border-yellow {
  border: 3px solid #fdb819;
}
.bg-yellow {
  background-color: #fdb819;
}
.bg-grey {
  background-color: #fdb819;
}

.hover-yellow:hover {
  background-color: #fdb819;
}

.bg {
  background-image:
  -webkit-gradient(linear, left bottom, left top, from(rgba(245,98,23,0.1)), to(rgba(11,72,107,0.3))),
  url(/static/media/1.7737814c.jpg);
  background-image:
  linear-gradient(to top, rgba(245,98,23,0.1), rgba(11,72,107,0.3)),
  url(/static/media/1.7737814c.jpg);
  
  
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-cat {
  
  background-image:
  -webkit-gradient(linear, left top, left bottom, from(rgba(253,184,25,1)), to(rgba(255,255,255,1))),
  url(/static/media/1.7737814c.jpg);
  
  background-image:
  linear-gradient(180deg, rgba(253,184,25,1) 0%, rgba(255,255,255,1) 100%),
  url(/static/media/1.7737814c.jpg);
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-cat-2 {
  background: #0B486B;  /* fallback for old browsers */

  background:-webkit-gradient(linear, left bottom, left top, from(rgba(245,98,23,0.1)), to(rgba(11,72,107,0.7))), url("https://images.unsplash.com/photo-1490742525749-fdda9a0ddcc3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80");

  background:linear-gradient(to top, rgba(245,98,23,0.1), rgba(11,72,107,0.7)), url("https://images.unsplash.com/photo-1490742525749-fdda9a0ddcc3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ui.search .prompt {
  border-radius : 0 !important;
}

.text-yellow {
  color: #fdb819;
}
.text-grey {
  color: #6c757d;
}
.text-grey:hover {
  text-decoration: none;
}

a,
a span, a p, .flip {
  display: inline-block ;
  color : #6c757d;
}
a span, a p, .flip {
  -webkit-transition: .5s;
  transition: .5s;
}
a:hover span, a:hover p, .flip:hover {
  -webkit-transform: rotateX(360deg) ;
  transform: rotateX(360deg);
  text-decoration: underline;
  color: #fdb819;
}

.div-divider {
  border-bottom: 3px solid #fdb819;
  height: 1px;
  width:60%;
  margin: 0px auto;
}

.img-yellow {
  -webkit-filter: brightness(0) saturate(100%) invert(91%) sepia(26%) saturate(6699%) hue-rotate(345deg) brightness(104%) contrast(98%);
          filter: brightness(0) saturate(100%) invert(91%) sepia(26%) saturate(6699%) hue-rotate(345deg) brightness(104%) contrast(98%);
}
